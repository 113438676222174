
import { fetchPage, Page } from "../serviceClients/pageWebServices";
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { pushError } from "@/core";

@Component
export default class WhyJoinUsComponent extends Vue {
  isLoadingAssistant = false;
  isLoadingClient = false;

  assistantPage: Page = {} as Page;
  clientPage: Page = {} as Page;

  activeTabIndex: number = 0;

  get clientPageText() {
    return this.clientPage?.text;
  }

  get clientPageTitle() {
    return this.clientPage?.title;
  }

  get assistantPageText() {
    return this.assistantPage?.text;
  }

  get assistantPageTitle() {
    return this.assistantPage?.title;
  }

  async mounted() {
    store.commit.module.setActiveModuleName(this.$t("Why join us?"));

    await Promise.all([this.loadAssistant(), this.loadClient()])
      this.makeActivable();
  }

  async loadAssistant() {
    this.isLoadingAssistant = true;
    try {
      this.assistantPage = (await fetchPage("WhyJoinUsAssistant")).data;
    } catch (error) {
      pushError(error.response?.data?.error || error);
    } finally {
      this.isLoadingAssistant = false;
    }
  }

  async loadClient() {
    this.isLoadingClient = true;
    try {
      this.clientPage = (await fetchPage("WhyJoinUsClient")).data;
    } catch (error) {
      pushError(error.response?.data?.error || error);
    } finally {
      this.isLoadingClient = false;
    }
  }

  makeActivable() {
    this.$nextTick(() => {
      this.$nextTick(() => {
        const coll = document.querySelectorAll('.collapse-header')
        var i;

        for (i = 0; i < coll.length; i++) {
          coll[i].addEventListener("click", function () {
            // @ts-ignore
            this.classList.toggle('collapse-content-active')
            // @ts-ignore
            var content = this.nextElementSibling
            if (content.style.maxHeight) {
              content.style.maxHeight = null
              content.style.marginTop = null
            } else {
              content.style.maxHeight = content.scrollHeight + "px"
              content.style.marginTop = '20px'
            }
          });
        }
      });
    });
  }
}
