import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/pages`;

export interface Page {
    id: string;
    title: string;
    text: string;
}

export function fetchPage(id: string) {
    return axios.default.get<Page>(`${url}/${id}`);
}

export function fetchBlogPostList() {
    return axios.default.get<Page[]>(`${url}/blog`);
}

export function fetchBlogPost(id: string) {
    return axios.default.get<Page>(`${url}/blog/${id}`);
}